const domainPriceList = [
  {
    tld: 'com',
    register: {
      priceINR: 890,
      priceUSD: 11.94
    },
    renew: {
      priceINR: 890,
      priceUSD: 11.94
    },
    transfer: {
      priceINR: 890,
      priceUSD: 11.94
    }
  },
  {
    tld: 'co',
    register: {
      priceINR: 129,
      priceUSD: 1.73
    },
    renew: {
      priceINR: 2050,
      priceUSD: 27.51
    },
    transfer: {
      priceINR: 2050,
      priceUSD: 27.51
    }
  },
  {
    tld: 'in',
    register: {
      priceINR: 890,
      priceUSD: 11.94
    },
    renew: {
      priceINR: 890,
      priceUSD: 11.94
    },
    transfer: {
      priceINR: 890,
      priceUSD: 11.94
    }
  },
  {
    tld: 'xyz',
    register: {
      priceINR: 950,
      priceUSD: 12.75
    },
    renew: {
      priceINR: 950,
      priceUSD: 12.75
    },
    transfer: {
      priceINR: 950,
      priceUSD: 12.75
    }
  },
  {
    tld: 'org',
    register: {
      priceINR: 960,
      priceUSD: 3429
    },
    renew: {
      priceINR: 960,
      priceUSD: 3429
    },
    transfer: {
      priceINR: 960,
      priceUSD: 3429
    }
  },
  {
    tld: 'net',
    register: {
      priceINR: 960,
      priceUSD: 12.88
    },
    renew: {
      priceINR: 960,
      priceUSD: 12.88
    },
    transfer: {
      priceINR: 960,
      priceUSD: 12.88
    }
  },
  {
    tld: 'co.in',
    register: {
      priceINR: 650,
      priceUSD: 8.72
    },
    renew: {
      priceINR: 650,
      priceUSD: 8.72
    },
    transfer: {
      priceINR: 650,
      priceUSD: 8.72
    }
  }
]

export default domainPriceList
