import React from 'react'
import {
  DomainPricingWrapper,
  DomainPricingInner
} from './domain-pricing-styles'
import { Container } from '../../common/common-styles'
import domainPricing from '../../../content/domain/domain-pricing'
import DomainPricingSingle from './domain-pricing-single'

function DomainPricing() {
  return (
    <DomainPricingWrapper id="pricing">
      <Container>
        <DomainPricingInner>
          {domainPricing.map(({ TLD, priceINR, priceUSD, url, detail }) => (
            <DomainPricingSingle
              TLD={TLD}
              priceINR={priceINR}
              priceUSD={priceUSD}
              detail={detail}
              url={url}
            />
          ))}
        </DomainPricingInner>
      </Container>
    </DomainPricingWrapper>
  )
}

export default DomainPricing
