import styled from 'styled-components'
import media from '../../utils/breakpoints'
import dotNetSVG from '../../../images/illustrations/dot-net.svg'
export const DomainPricingWrapper = styled.div`
  padding: 1.5rem 0;
  background-image: url(${dotNetSVG});
  background-repeat: repeat-x;
  background-position: center center;
  background-size: 6.2em;
  ${media.md} {
    padding: 3rem 0;
  }
`
export const DomainPricingInner = styled.div`
  ${media.sm} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }
  ${media.xl} {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
  }
`
export const DomainPricingSingleWrapper = styled.div`
  border: 2px #f3f4f7 solid;
  border-radius: 10px;
  padding: 2rem 1rem;
  margin: 1rem 0;
  background: #fff;
  a {
    display: block;
    margin: 0 auto;
    width: fit-content;
  }
  h2 {
    font-size: 3rem;
  }
  p.detail {
    font-size: 0.9rem;
    padding-top: 1rem;
  }
  div.price {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem 0;
    span {
      &.currency,
      &.year {
        color: var(--grey);
        font-size: 1.5rem;
        align-self: flex-end;
      }
      &.number {
        font-size: 3rem;
        font-weight: bold;
        padding: 0.5rem;
      }
      &.currency {
        align-self: center;
        font-size: 2.5rem;
      }
    }
  }
`
