import React, { useContext } from 'react'
import { DomainPriceChartWrapper, DomainPriceChartInner } from './domain-styles'
import { Container } from '../common/common-styles'
import PrimaryHeading from '../heading/primary-heading'
import domainPriceChart from '../../content/domain/domain-price-list'
import { GlobalCurrencyContext } from '../../context/global-context'
import Button from '../common/cta-button/button'

function DomainPriceList() {
  const { key, curr } = useContext(GlobalCurrencyContext)

  const title = 'Buy a domain name and create your website today'
  const subtitle =
    "The perfect domain lets people know at a glance why you're online (and why you're awesome). Find the right domain extension now to grab more attention and visitors."
  const data = domainPriceChart.map(({ tld, register, renew, transfer }) => (
    <div className="row">
      <div className="tld">.{tld}</div>

      <div className="price">
        {curr}
        {register[key]}
        <span>/yr</span>
      </div>

      <div className="price">
        {curr}
        {renew[key]}
        <span>/yr</span>
      </div>

      <div className="price">
        {curr}
        {transfer[key]}
        <span>/yr</span>
      </div>
    </div>
  ))
  return (
    <DomainPriceChartWrapper id="domain-pricing">
      <PrimaryHeading title={title} subtitle={subtitle} />
      <Container>
        <DomainPriceChartInner>
          <div className="row">
            <div className="heading">TLD</div>
            <div className="heading">Register</div>
            <div className="heading">Renewal</div>
            <div className="heading">Transfer</div>
          </div>
          {data}
          <Button href="https://my.bluegeekhosting.com/cart.php?a=add&domain=register">
            View More
          </Button>
        </DomainPriceChartInner>
      </Container>
    </DomainPriceChartWrapper>
  )
}

export default DomainPriceList
