import React from 'react'
import { DomainPricingSingleWrapper } from './domain-pricing-styles'
import Button from '../../common/cta-button/button'
import { useContext } from 'react'
import { GlobalCurrencyContext } from '../../../context/global-context'

function DomainPricingSingle({ TLD, priceUSD, priceINR, url, detail }) {
  const { key, curr } = useContext(GlobalCurrencyContext)
  const price = key === 'priceINR' ? priceINR : priceUSD

  return (
    <DomainPricingSingleWrapper>
      <h2>
        <span className="accent-pink">.</span>
        {TLD}
      </h2>
      <p className="detail">{detail}</p>
      <div className="price">
        <span className="currency">{curr}</span>
        <span className="number">{price}</span>
        <span className="year">/year</span>
      </div>
      <Button
        href="https://my.bluegeekhosting.com/cart.php?a=add&domain=register"
        hideArrow
        size="big"
      >
        Buy Now
      </Button>
    </DomainPricingSingleWrapper>
  )
}

export default DomainPricingSingle
