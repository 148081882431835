import domainImage from '../../images/features/domains.svg'

const domainFeatures = {
  title: 'It all starts with a domain name',
  subtitle:
    'We make registering, hosting, and managing domains easy and affordable.Choose the right hosting package to create your unique online presence today.',
  featureImage: domainImage,
  features: [
    {
      name: 'Choose Your Domain Name',
      desc:
        'Every website starts with a great domain name.Blue Geek Hosting offers you a wide variety of domains. Register or transfer your domain name in a snap.'
    },
    {
      name: 'Lowest Everyday Prices On the Web!',
      desc:
        'Blue Geek Hosting offers hosting plans cheaper and affordable than the competition. Start your site and create your unique online presence today.'
    },
    {
      name: 'Host Your Website',
      desc:
        'Blue Geek Hosting offers hosting plans that are secure, reliable, and high-performing for just ₹45/month. There’s a perfect hosting solution for every website.'
    }
  ]
}

export default domainFeatures
