import React from 'react'
import SecondaryHeader from '../components/header/secondary/secondary-header'
import TestimonialSection from '../components/testimonial/testimonial-section'
import FaqSection from '../components/faq/faq-section'
import DomainSearch from '../components/domain/domain-search'
import DomainPricing from '../components/pricing/domain-pricing/domain-pricing'
import DomainPriceList from '../components/domain/domain-price-chart'
import DomainFeature from '../components/features/two-block-feature/domain-feature/domain-features'
import SEO from '../components/seo/seo'
function Domain() {
  const title = 'Search for your domain name'
  const subtitle =
    'Enter domain name of your choice and pick any extension name on the next step (choose between .com, .xyz, .co, .in, .net and many more'
  return (
    <>
      <SEO title="Domains" />
      <SecondaryHeader title={title} subtitle={subtitle} />
      <DomainSearch />
      <DomainPricing />
      <DomainFeature />
      <DomainPriceList />
      <TestimonialSection />
      <FaqSection />
    </>
  )
}

export default Domain
